import React, { useCallback } from 'react'
import classNames from 'classnames'
import { RadioGroup } from '@headlessui/react'
import { useFormikContext } from 'formik'
import { RadioOption } from '@/components/svg'
import { formUtil } from '@/utils'
import { Text } from '@/components/common'
import { FormInput } from '../types'
import { Option } from '../../Select/Select'

export interface Props extends FormInput {
  name: string
  options: Option[] | Option[][]
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
}

const FormRadio: React.FC<Props> = ({
  name,
  options,
  value = '',
  onChange,
  disabled,
  className,
}) => {
  const { touched, setFieldTouched } = useFormikContext()

  const handleChange = useCallback(
    (value) => {
      if (!formUtil.lookupValue(touched, name)) {
        setFieldTouched(name, true)
      }

      const event = {
        target: { name, value },
      } as React.ChangeEvent<HTMLSelectElement>
      onChange(event)
    },
    [name, onChange, touched, setFieldTouched]
  )

  const grouped: Option[][] = formUtil.getGroupedOptions(options)

  return (
    <RadioGroup
      value={value}
      onChange={handleChange}
      disabled={disabled}
      className={className}
    >
      <input type="hidden" aria-labelledby={`${name}-label`} name={name} />
      <div className="flex flex-wrap">
        {grouped.map((group) => {
          return group.map(({ label: optionLabel, value: optionValue }) => (
            <RadioGroup.Option
              key={optionValue}
              value={optionValue}
              className="flex items-center my-1 mr-8 cursor-pointer"
            >
              {({ checked }) => (
                <>
                  <RadioOption
                    className={classNames({
                      'w-4 h-4 mr-1': true,
                      'fill-oxide stroke-oxide': checked,
                      'stroke-gray fill-transparent': !checked,
                    })}
                  />
                  <Text as="span" preset="body.md">
                    {optionLabel}
                  </Text>
                </>
              )}
            </RadioGroup.Option>
          ))
        })}
      </div>
    </RadioGroup>
  )
}

export default FormRadio
